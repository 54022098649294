import {
  Z_PartnerSettings_LabourRates,
  Z_TenantLink,
  Z_WarrantorLink,
  Z_WarrantyTemplateLink,
  Z_WarrantyTemplateParameters,
} from '@rabbit/data/types';
import {
  Z_RegistrationsWarrantyOptionShape,
  Z_WarrantyOfferFullDataShape,
} from '@rabbit/elements/shared-types';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { z } from 'zod';
import { ICF_Base } from '../base';

/* -------------------------------------------------------------------------- */
/*                               Warranty offers                              */
/* -------------------------------------------------------------------------- */

/* ---------- Get full warranty offer data for lookup table editing --------- */

export const ICF_GetAllWarrantyOffers_Full = z
  .object({
    partnerTenantLink: Z_TenantLink,
    warrantorLink: Z_WarrantorLink,
  })
  .merge(ICF_Base);

export const OCF_GetAllWarrantyOffers_Full = z.array(
  Z_WarrantyOfferFullDataShape
);

export const CFC_GetAllWarrantyOffers_Full = MakeCloudFunctionSpec(
  'api/legacy/cfcgetallwarrantyoffersfull',
  ICF_GetAllWarrantyOffers_Full,
  OCF_GetAllWarrantyOffers_Full
);

/* --------------------------- Get approved offers -------------------------- */
const ICF_GetApprovedWarrantyOffers = z
  .object({
    params: Z_WarrantyTemplateParameters,
    dimensions: z.array(z.string()),
    partnerTenantLink: Z_TenantLink,
    warrantorLink: Z_WarrantorLink,
  })
  .merge(ICF_Base);

const OCF_GetApprovedWarrantyOffers = z.object({
  offers: z.array(Z_RegistrationsWarrantyOptionShape),
  labourRates: Z_PartnerSettings_LabourRates.optional(),
});

export const CFC_GetApprovedWarrantyOffers = MakeCloudFunctionSpec(
  'api/legacy/cfcgetapprovedwarrantyoffers',
  ICF_GetApprovedWarrantyOffers,
  OCF_GetApprovedWarrantyOffers
);

/* ------------------------------ Create offer ------------------------------ */
export const ICF_CreateWarrantyOfferForPartner = z
  .object({
    tenantLink: Z_TenantLink,
    warrantorLink: Z_WarrantorLink,
    templateLink: Z_WarrantyTemplateLink,
    title: z.string().nullable().optional(),
    lookupKeys: z.array(z.string()).optional(),
  })
  .merge(ICF_Base);

export const OCF_CreateWarrantyOfferForPartner = z.object({
  offerId: z.string(),
});

export const CFC_CreateWarrantyOfferForPartner = MakeCloudFunctionSpec(
  'api/legacy/cfccreatewarrantyofferforpartner',
  ICF_CreateWarrantyOfferForPartner,
  OCF_CreateWarrantyOfferForPartner
);

/* -------------------- "Delete" (actually disable) offer ------------------- */
const ICF_DisableWarrantyOffer = z.object({
  offerId: z.string(),
});

const OCF_DisableWarrantyOffer = z.object({
  message: z.string(),
});

export const CFC_DisableWarrantyOffer = MakeCloudFunctionSpec(
  'cfcdisablewarrantyoffer',
  ICF_DisableWarrantyOffer,
  OCF_DisableWarrantyOffer
);
