import React, { useEffect } from 'react';

import i18n, { BackendModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { CFC_GetLexicon, GenericLexicon } from '@rabbit/mixmaster/core';
import { CloudFunctionSetRider } from '@rabbit/firebase/doctype';
import { RecipeToLanguageString } from './recipe';

let booted = false;
let bootStarted = false;

console.debug('MixMaster: First Chance @ ', new Date().getTime());

export async function BootI18N(bootstrap: GenericLexicon) {
  if (bootStarted) {
    console.debug('MixMaster: Already Booted');
    return;
  }
  bootStarted = true;

  console.debug('MixMaster: BootI18N @ ', new Date().getTime(), bootstrap);

  async function GetLanguage(inlanguage: string, namespace: string) {
    const language = inlanguage.split('@').join('_'); // Split and join since i18n has its own ideas about underscore
    console.debug('MixMaster: GetLanguage', language, namespace);
    if (language === 'bootstrap') return bootstrap;
    // DEBUGGING: wait 2 seconds
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await CFC_GetLexicon.call({
      id: `${language}`,
      // id: `${language}/${namespace}`,
    });

    if (!result.ok) {
      throw new Error(`MixMaster: Failed to get lexicon: ${result.error}`);
    }
    console.log('SETTING LEXICON:', result.data.body);
    // store the tenantLink in local storage so it can be used to load up the app config
    // and also dispatch an event to let the appConfigContext know the tenant has changed
    const activeTenantInLS = localStorage.getItem('activeTenant');
    if (activeTenantInLS !== result.data.body.tenantLink) {
      localStorage.setItem('activeTenant', result.data.body.tenantLink);
      window.dispatchEvent(new Event('activeTenantChange'));
    }
    return result.data.body;
  }

  const loaderBackend: BackendModule = {
    type: 'backend',
    init: function (services, backendOptions, i18nextOptions) {
      /* use services and options */
      console.debug('MixMaster: BACKEND INIT');
    },
    read: async function (language, namespace, callback) {
      try {
        const results = await GetLanguage(language, namespace);
        callback(null, results);
      } catch (e) {
        /* if method fails/returns an error, call this: */
        console.error('MixMaster: error in loading', language, namespace, e);
        callback('Loading error', null);
        throw e;
      }
    },

    // only used in backends acting as cache layer
    save: function (language, namespace, data) {
      // store the translations
      // console.log('BACKEND SAVE', language, namespace, data);
    },

    create: function (languages, namespace, key, fallbackValue) {
      /* save the missing translation */
      console.debug(
        'MixMaster: MISSING LANGUAGE ENTRY\n',
        `  "${key}": "${fallbackValue}"`
      );
    },
  };

  const InitialInitialisation = await i18n.use(initReactI18next);

  const FurtherInitialisation = async () => {
    await InitialInitialisation
      // load translation using our custom backend loader
      .use(loaderBackend)
      // pass the i18n instance to react-i18next.
      // // init i18next
      // // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        debug: false,
        fallbackLng: 'base',
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
          useSuspense: false,
        },
        saveMissing: true,
      });
    // Sensing goes here
    booted = true;

    const language = RecipeToLanguageString();

    await ChangeLanguage(language);
  };

  void FurtherInitialisation();

  await InitialInitialisation;
}

export function HasLanguageBooted() {
  return booted;
}

export async function ChangeLanguage(language: string) {
  CloudFunctionSetRider('lex', language);
  await i18n.changeLanguage(language.split('_').join('@')); // Split and join since i18n has its own ideas about underscore
}
