import { Functions } from '@firebase/functions';
import { RabbitEnvironments } from '@rabbit/data/types';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const FIREBASE_MODE = import.meta.env['VITE_FIREBASE_MODE'];

export const apiCallable =
  (firebaseFunctions: Functions, path: string) => async (params: any) => {
    if (!path.startsWith('/')) {
      path = '/' + path;
    }

    let urlPrefix = '';
    if (FIREBASE_MODE !== RabbitEnvironments.EMULATOR) {
      if (window.location.hostname === 'localhost') {
        urlPrefix = `https://${firebaseFunctions.region}-${firebaseFunctions.app.options.projectId}.cloudfunctions.net/api`;
      }
    } else {
      urlPrefix = `http://localhost:5001/${firebaseFunctions.app.options.projectId}/${firebaseFunctions.region}/api`;
    }

    const token = await getAuth(
      firebaseFunctions.app
    ).currentUser?.getIdToken();

    const response = await axios
      .post(urlPrefix + path, params, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .catch((error) => {
        console.error('Error calling API', error.response);
        throw error;
      });
    return response.data;
  };
