import { CaseFlowConfiguration } from '@rabbit/data/types';
import { CaseFlowConfigurationRegistry } from '../configuration';
import {
  CaseFlowConfig_Facts_DocLinks,
  CaseFlowConfig_Facts_Comments,
  CaseFlowConfig_Facts_IssueDetails,
  CaseFlowConfig_Facts_Delegates,
  CaseFlowConfig_Facts_Holding,
  CaseFlowConfig_Facts_Consumer,
  CaseFlowConfig_Facts_CaseStatus,
  CaseFlowConfig_Facts_UploadedFiles,
  CaseFlowConfig_Facts_PartnerData,
  CaseFlowConfig_Facts_CostLogging,
} from '../_common/facts';
import {
  CaseflowActorTypes,
  CaseFlowCaseStations,
  CaseflowCaseTypes,
} from '../_common/types';
import {
  CaseFlowActions_Fatbikes_ExternalRepairer,
  CaseFlowActions_Fatbikes_Warrantor,
} from './types';

/* -------------------------------------------------------------------------- */
/*                                   Actors                                   */
/* -------------------------------------------------------------------------- */

const actors: CaseFlowConfiguration['actors'] = {
  consumer: {
    tech_description: 'holder of broken item',
  },
  warrantor: {
    tech_description: 'entity responsible for the warranty',
    proxy: {
      facts: [
        'administrative_cost_data',
        'parts_used_cost_data',
        'repair_time_data',
        'shipping_cost_data',
        'travel_cost_data',
        'other_cost_data',
        'consumer_holding',
        'consumer_persona_id',
        'holding_vendable_id',
        'consumer_issue_type',
        'consumer_issue_type_ref',
        'consumer_issue_description',
        'consumer_claim_evidence',
        'consumer_proof_of_purchase',
        'warranty_link',
        'vendable_version_number',
        'final_assessment',
        'goodwill_warranty_initial',
        'goodwill_warranty_final',
        'delegate_repairer_id',
        'delegate_repairer_name',
        'consumer_name',
        'consumer_holding_name',
        'holding_warranty_term',
        'purchase_date',
        'consumer_email',
        'external_repairer_comment',
        'external_repair_invoices',
        'serial_number',
        'serial_number_proof',
      ],
    },
  },
  external_repairer: {
    tech_description: 'external entity responsible for the repair of the item',
  },
};

/* -------------------------------------------------------------------------- */
/*                                    Facts                                   */
/* -------------------------------------------------------------------------- */

const fatbikesFacts: CaseFlowConfiguration['facts'] = {
  serial_number: {
    type: 'String',
    label: 'Serial number',
  },
  serial_number_proof: {
    type: 'UserUploadedDocument[]',
    label: 'Serial number proof',
  },
  external_repair_invoices: {
    type: 'CFCF_ExternalRepairInvoice[]',
    label: 'Repair invoice',
  },
  external_repairer_comment: {
    type: 'String',
    label: 'External repairer comment',
  },
};

const facts: CaseFlowConfiguration['facts'] = {
  /* --------------------------- Fatbikes specific facts -------------------------- */
  ...fatbikesFacts,
  /* ------------------------------ Common facts ------------------------------ */
  ...CaseFlowConfig_Facts_CostLogging,
  ...CaseFlowConfig_Facts_DocLinks,
  ...CaseFlowConfig_Facts_Comments,
  ...CaseFlowConfig_Facts_IssueDetails,
  ...CaseFlowConfig_Facts_Delegates,
  ...CaseFlowConfig_Facts_Holding,
  ...CaseFlowConfig_Facts_Consumer,
  ...CaseFlowConfig_Facts_CaseStatus,
  ...CaseFlowConfig_Facts_UploadedFiles,
  ...CaseFlowConfig_Facts_PartnerData,
};

/* -------------------------------------------------------------------------- */
/*                                  Stations                                  */
/* -------------------------------------------------------------------------- */

const stations: CaseFlowConfiguration['stations'] = {
  _birth: {
    actions: {
      _onEnter: {
        available_to: [
          CaseflowActorTypes.CONSUMER,
          CaseflowActorTypes.EXTERNAL_REPAIRER,
          CaseflowActorTypes.WARRANTOR,
        ],
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.PRELIMINARY_ASSESSMENT,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.PRELIMINARY_ASSESSMENT]: {
    actions: {
      [CaseFlowActions_Fatbikes_ExternalRepairer.RepairCovered]: {
        available_to: [
          CaseflowActorTypes.EXTERNAL_REPAIRER,
          CaseflowActorTypes.WARRANTOR,
        ],
        label: 'Repair covered by warranty',
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
          },
        ],
      },
      [CaseFlowActions_Fatbikes_ExternalRepairer.RepairNotCovered]: {
        available_to: [
          CaseflowActorTypes.EXTERNAL_REPAIRER,
          CaseflowActorTypes.WARRANTOR,
        ],
        label: 'Out of warranty repair',
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.IN_REPAIR,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION]: {
    actions: {
      [CaseFlowActions_Fatbikes_ExternalRepairer.SubmitInvoice]: {
        available_to: [CaseflowActorTypes.EXTERNAL_REPAIRER],
        label: 'Submit invoice',
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT]: {
    actions: {
      // if warrantor approves we move to invoice accepted
      [CaseFlowActions_Fatbikes_Warrantor.ApproveInvoice]: {
        available_to: [CaseflowActorTypes.WARRANTOR],
        label: 'Approve invoice',
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.INVOICE_ACCEPTED,
          },
        ],
      },

      // if we reject, we go to back to invoice submission
      [CaseFlowActions_Fatbikes_Warrantor.RejectInvoice]: {
        available_to: [CaseflowActorTypes.WARRANTOR],
        label: 'Reject invoice',
        steps: [
          // one day
          // {
          //   type: 'req_facts',
          //   facts: ['external_repair_invoices'],
          // },
          {
            type: 'go_station',
            station: CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.INVOICE_ACCEPTED]: {
    actions: {
      [CaseFlowActions_Fatbikes_ExternalRepairer.InitiateRepair]: {
        available_to: [CaseflowActorTypes.EXTERNAL_REPAIRER],
        label: 'Initiate repair',
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.IN_REPAIR,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.IN_REPAIR]: {
    actions: {
      [CaseFlowActions_Fatbikes_ExternalRepairer.MarkRepairComplete]: {
        available_to: [CaseflowActorTypes.EXTERNAL_REPAIRER],
        label: 'Mark repair as complete',
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.REPAIRED,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.REPAIRED]: {
    actions: {
      [CaseFlowActions_Fatbikes_Warrantor.CloseCase]: {
        available_to: [
          CaseflowActorTypes.WARRANTOR,
          CaseflowActorTypes.EXTERNAL_REPAIRER,
        ],
        label: 'Close case',
        steps: [
          {
            type: 'go_station',
            station: CaseFlowCaseStations.CLOSED,
          },
        ],
      },
    },
  },
  [CaseFlowCaseStations.CLOSED]: {
    // No steps or actions are required. This station marks the end of Journey for a case
    actions: {},
  },
};

/* -------------------------------------------------------------------------- */
/*                               Global Actions                               */
/* -------------------------------------------------------------------------- */

const genericActions: CaseFlowConfiguration['global_actions'] = {
  contact_customer_generic: {
    label: 'Contact customer for more info',
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
    ],
    steps: [
      {
        type: 'req_facts',
        facts: ['comment_to_customer'],
      },
    ],
  },
  internal_comment_generic: {
    label: 'Leave internal comment',
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'req_facts',
        facts: ['internal_comment'],
      },
    ],
  },
  assign_case: {
    label: 'Assign case',
    available_to: [CaseflowActorTypes.WARRANTOR],
    params: {
      delegate_repairer_id: {
        type: 'String',
        label: 'Delegate repairer persona',
        required: true,
      },
      delegate_repairer_name: {
        type: 'String',
        label: 'Delegate repairer name',
        required: true,
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'delegate_repairer_id',
        value: '[[delegate_repairer_id]]',
      },
      {
        type: 'set_fact',
        fact: 'delegate_repairer_name',
        value: '[[delegate_repairer_name]]',
      },
      {
        type: 'set_actor',
        role: CaseflowActorTypes.EXTERNAL_REPAIRER,
        persona_value: '[[delegate_repairer_id]]',
      },
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[delegate_repairer_id]]',
      },
    ],
  },
  //Set the spotlight to point at the consumer
  spotlight_on_consumer: {
    label: 'Spotlight on consumer',
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
      CaseflowActorTypes.CONSUMER,
    ],
    params: {
      consumer: {
        type: 'String',
        label: 'Consumer persona',
        required: true,
        get_from: ['fact:consumer_persona_id', 'actor:consumer'],
      },
    },
    steps: [
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[consumer]]',
      },
    ],
  },
  // Set the spotlight to point at the repairer, which may be the delegate repairer if it has been set as a fact
  spotlight_on_repairer: {
    label: 'Spotlight on repairer',
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
      CaseflowActorTypes.CONSUMER,
    ],
    params: {
      external_repairer: {
        type: 'String',
        label: 'Repairer persona',
        required: true,
        get_from: ['fact:delegate_repairer_id', 'actor:external_repairer'],
      },
    },
    steps: [
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[external_repairer]]',
      },
    ],
  },
  spotlight_on_warrantor: {
    label: 'Spotlight on warrantor',
    available_to: [
      CaseflowActorTypes.WARRANTOR,
      CaseflowActorTypes.EXTERNAL_REPAIRER,
      CaseflowActorTypes.CONSUMER,
    ],
    params: {
      warrantor: {
        type: 'String',
        label: 'Warrantor persona',
        required: true,
        get_from: ['actor:warrantor'],
      },
    },
    steps: [
      {
        type: 'alter_spotlight',
        clear: true,
        add: '[[warrantor]]',
      },
    ],
  },
};

/* -------------- Actions for moving the case between stations -------------- */
const stationMovementActions: CaseFlowConfiguration['global_actions'] = {
  [CaseFlowActions_Fatbikes_Warrantor.MoveToPreliminaryAssessment]: {
    label: 'Move to preliminary assessment',
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.PRELIMINARY_ASSESSMENT,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToRepairInvoiceSubmission]: {
    label: 'Move to repair invoice submission',
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToRepairInvoiceAssessment]: {
    label: 'Move to repair invoice assessment',
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToInvoiceAccepted]: {
    label: 'Move to invoice accepted',
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.INVOICE_ACCEPTED,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToInRepair]: {
    label: 'Move to in repair',
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.IN_REPAIR,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToRepaired]: {
    label: 'Move to repaired',
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.REPAIRED,
      },
    ],
  },
  [CaseFlowActions_Fatbikes_Warrantor.MoveToClosed]: {
    label: 'Move to closed',
    available_to: [CaseflowActorTypes.WARRANTOR],
    steps: [
      {
        type: 'go_station',
        station: CaseFlowCaseStations.CLOSED,
      },
    ],
  },
};

/* --------------------- Actions for logging claim costs -------------------- */
const costLoggingActions: CaseFlowConfiguration['global_actions'] = {
  /* -------------------------------------------------------------------------- */
  /*                                  Warrantor                                 */
  /* -------------------------------------------------------------------------- */
  /* --------------------------- Administrative time -------------------------- */
  log_costs_administrative_time: {
    available_to: [CaseflowActorTypes.WARRANTOR],
    label: 'Log administrative time costs',
    params: {
      administrative_cost_data: {
        type: 'CFCF_AdministrativeCostLog',
        label: 'Administrative time cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'administrative_cost_data',
        value: '[[administrative_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: ' internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* ------------------------------- Parts used ------------------------------- */
  log_costs_parts_used: {
    available_to: [CaseflowActorTypes.WARRANTOR],
    label: 'Log parts used costs',
    params: {
      parts_used_cost_data: {
        type: 'CFCF_PartsUsed',
        label: 'Parts used cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'parts_used_cost_data',
        value: '[[parts_used_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* ------------------------------- Repair time ------------------------------ */
  log_costs_repair_time: {
    available_to: [CaseflowActorTypes.WARRANTOR],
    label: 'Log repair time costs',
    params: {
      repair_time_data: {
        type: 'CFCF_RepairTimeLog',
        label: 'Repair time cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'repair_time_data',
        value: '[[repair_time_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* -------------------------------- Shipping -------------------------------- */
  log_costs_shipping: {
    available_to: [CaseflowActorTypes.WARRANTOR],
    label: 'Log shipping costs',
    params: {
      shipping_cost_data: {
        type: 'CFCF_ShippingCostLog',
        label: 'Shipping cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'shipping_cost_data',
        value: '[[shipping_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* --------------------------------- Travel -------------------------------- */
  log_costs_travel: {
    available_to: [CaseflowActorTypes.WARRANTOR],
    label: 'Log travel costs',
    params: {
      travel_cost_data: {
        type: 'CFCF_TravelCostLog',
        label: 'Travel cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'travel_cost_data',
        value: '[[travel_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
  /* ------------------------------- Other costs ------------------------------ */
  log_costs_other: {
    available_to: [CaseflowActorTypes.WARRANTOR],
    label: 'Log other costs',
    params: {
      other_cost_data: {
        type: 'CFCF_OtherCostLog',
        label: 'Other cost',
        required: true,
      },
      internal_comment: {
        type: 'String',
        label: 'Internal comment',
      },
    },
    steps: [
      {
        type: 'set_fact',
        fact: 'other_cost_data',
        value: '[[other_cost_data]]',
      },
      {
        type: 'set_fact',
        fact: 'internal_comment',
        value: '[[internal_comment]]',
      },
    ],
  },
};
/* ------------------- The complete global actions object ------------------- */
const global_actions: CaseFlowConfiguration['global_actions'] = {
  ...genericActions,
  ...stationMovementActions,
  ...costLoggingActions,
};

export const FatbikesConfig: CaseFlowConfiguration = {
  actors,
  facts,
  stations,
  global_actions,
};

CaseFlowConfigurationRegistry.Register(
  FatbikesConfig,
  CaseflowCaseTypes.FATBIKES
);
